.container {
    width: 100%;
    display: flex;

 /*    margin: 20px; */
    border-radius: 6px;
    justify-content: center;
    -webkit-box-shadow: 4px 2px 16px 0 rgba(50, 50, 50, 0.62);
    -moz-box-shadow: 4px 2px 16px 0 rgba(50, 50, 50, 0.62);
    box-shadow: 1px 2px 6px 0 rgba(50, 50, 50, 0.62);
}