body {
  overflow: scroll;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding: 15px;
  max-width: 960px;
}
.welpenCard {
  position: relative;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  border-bottom: brown solid 2px;
  /* background: lightcyan; */
  height: 330px;
}
.imageContainer {
  width: 300px;
  height: 300px;
  /* background: blueviolet; */
  object-fit: contain;
  margin: 1rem;
  /* margin: 1rem;
  align-self: flex-end;
  text-align: right;
  justify-content: space-between; */
}

.imageContainer img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.dataContainer {
  /* background: lightgoldenrodyellow; */
  width: 70%;
  /* flex: 2;
  object-fit: contain; */
  text-align: left;
  padding: 20px;
  /* flex-direction: column; */
  /* align-self: flex-start; */
  justify-content: space-between;
}

.btn {
  /* background: aqua; */
  display: flex;
  width: 62%;
  justify-content: space-between;
  position: absolute;
  bottom: 1rem;
}

.btn button {
  display: flex;
  margin: 0;
}
.containerdog1 {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
.dogbox1 {
  flex: 3;
  padding: 20px;
  justify-content: space-between;
}
.dogbox2 {
  flex: 3;
  padding: 20px;
  justify-content: space-between;
}

/* img {
  padding: 10px 0;
} */
.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: brown solid 2px;
}

.header {
  font-size: 1.5rem;
  color: brown;
  padding-bottom: 20px;
}
.dob {
  padding-bottom: 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
  overflow-x: auto;
}
th,
td {
  padding-right: 10px;
}
.expositions {
  padding-top: 20px;
}
/* .translations{
  padding-top: 30px;
} */

@media only screen and (max-width: 700px) {
  th,
  td {
    padding-right: 6px;
  }

  .welpenCard {
    flex-direction: column;
    height: 600px;
  }

  .btn {
    width: 90%;
  }

  .btn button {
    display: flex;
    margin: 0;
    font-size: 0.8rem;
  }

  .dataContainer {
    width: 100%;
  }

  .welpenCard,
  .imageContainer {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 701px) and (max-width: 768px) {
  .dataContainer {
    width: 50%;
  }

  .btn {
    width: 45%;
  }

  .btn button {
    display: flex;
    margin: 0;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 400px) {
  .btn button {
    font-size: 0.6rem;
  }
}
