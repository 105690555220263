.wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  /* margin: 60px auto;
  font-size: 10px;
  position: fixed; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* text-indent: -9999em; */
  border-top: 1.1em solid rgba(167, 69, 39, 0.2);
  border-right: 1.1em solid rgba(167, 69, 39, 0.2);
  border-bottom: 1.1em solid rgba(167, 69, 39, 0.2);
  border-left: 1.1em solid #a74527;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

  .loaderText {
    margin-bottom: 1rem;
  }


@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* +++++++++++++++mobile++++++++++++++++ */
@media screen and (max-width: 767px) {
  .loader,
  .loader:after {
    width: 5em;
    height: 5em;
  }

  .loader {
    border-top: 0.8em solid rgba(167, 69, 39, 0.2);
    border-right: 0.8em solid rgba(167, 69, 39, 0.2);
    border-bottom: 0.8em solid rgba(167, 69, 39, 0.2);
    border-left: 0.8em solid #a74527;
  }


}
