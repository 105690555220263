
.MapSize{
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    text-decoration: none;
  }
  .googleBox{
    /* margin-top: 80px; */
    box-sizing: border-box;
  }
  
  .googleMapClose{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .googleMapClose i{
    color: black;
    font-size: 1.25rem;
  }
  
  /* Form Css */
  
  .box {
    box-sizing: border-box;
  }
  
  .row h1 {
    text-align: center;
    font-size: xx-large;
  }
  
  .boxForm {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    padding: 24px;
    transform: translate(-50%, -50%);
    background: #AFA596;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
  
  textarea:hover {
    background: silver;
    color: #fff;
    box-shadow: 0 0 5px silver;
  }
  
  input:hover {
    background: silver;
    color: #fff;
    box-shadow: 0 0 5px silver;
  }
  
  input {
    width: 100%;
    padding: 10px 0;
    font-size: 1.25rem;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    margin-bottom: 5px;
  }
  
  textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 1rem;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .row label {
    display: flex;
    color: black;
    font-size: 0.875rem;
  }
  
  .closeIframe {
    position: relative;
    top: -10px;
    left: 400px;
  }
  
  .row i {
    color: black;
    font-size: larger;
  }
  
  form {
    padding: 10px 20px;
    color: black;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 0;
    letter-spacing: 2px;
  }
  
  @media screen and (max-width: 325px) {
    .boxForm {
      width: 250px;
    }
  
    .row h1 {
      font-size: x-small;
    }
  
    input {
      padding: 5px 0;
      font-size: 0.875rem;
    }
  
    textarea {
      font-size: 0.875rem;
    }
  
    .row label {
      font-size: 0.563rem;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 170px;
    }
  
    .row i {
      font-size: small;
    }
  
    form {
      font-size: 0.875rem;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 326px) and (max-width: 376px) {
    .boxForm {
      width: 300px;
    }
  
    .row h1 {
      font-size: large;
    }
  
    input {
      padding: 5px 0;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  
    textarea {
      font-size: 0.875rem;
    }
  
    .row label {
      font-size: 0.75rem;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 230px;
    }
  
    .row i {
      font-size: small;
    }
  
    form {
      padding: 10px 10px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 377px) and (max-width: 409px){
    .boxForm {
      width: 300px;
    }
  
    .row h1 {
      font-size: large;
    }
  
    input {
      padding: 5px 0;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  
    textarea {
      font-size: 0.875rem;
    }
  
    .row label {
      font-size: 0.75rem;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 300px;
    }
  
    .row i {
      font-size: small;
    }
  
    form {
      padding: 10px 10px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 410px) and (max-width: 480px){
    .boxForm {
      width: 400px;
    }
  
    .row h1 {
      font-size: x-large;
    }
  
    input {
      padding: 5px 0;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  
    textarea {
      font-size: 0.875rem;
    }
  
    .row label {
      font-size: 0.75rem;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 330px;
    }
  
    .row i {
      font-size: small;
    }
  
    form {
      padding: 10px 10px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 680px) {
    .boxForm {
      width: 400px;
    }
  
    .row h1 {
      font-size: x-large;
    }
  
    input {
      padding: 5px 0;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .row label {
      font-size: 0.75;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 300px;
    }
  
    .row i {
      font-size: small;
  
    }
  
    form {
      padding: 10px 10px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 681px) and (max-width: 980px) {
    .boxForm {
      width: 400px;
    }
  
    .row h1 {
      font-size: x-large;
    }
  
    input {
      padding: 5px 0;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .row label {
      font-size: 0.75rem;
    }
  
    .closeIframe {
      position: relative;
      top: -10px;
      left: 330px;
    }
  
    .row i {
      font-size: small;
    }
  
    form {
      padding: 10px 10px;
      margin-top: 10px;
    }
  }
  