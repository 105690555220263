/* .imageCarousel{
    
    height: 300px;
    
} */

.imageCarousel{
    background: transparent;
    width: 100%;
    margin-left: auto;
    margin-right: auto; 
    padding-bottom: 8%;
    padding-top: 2%;
}