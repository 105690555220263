.footer {
  background: rgb(167, 69, 39);
  color: white;
  display: flex;
  flex-direction: row;
/*   justify-content: space-between;
 */  align-items: center;
  
  /* bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
   */
}

.copyright{
  font-size: small;
  margin-right: 2.7rem;
  margin-left: 4rem;
/*   margin-bottom: 40px; */
}

.shareIcons{
  margin-left: 2.7rem;
}
.shareIcons button{
  margin: 10px 8px;

}

@media screen and (max-width: 768px) {
  .footer {
  
    flex-direction: column;
    justify-content: center;
  }
  
  .copyright{
   margin-right: 0;
   margin-left: 0px;
   margin-bottom: 40px;
  }
  
  .shareIcons{
    margin-left: 0;
    
  }

} 
