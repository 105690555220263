.container {
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  max-width: 960px;
  margin-bottom: 100px;
}
.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: brown solid 2px;
}
.aboutUsCard {
  display: flex;
}

.spanText {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-stretch: extra-expanded;
}

.dataContainer {
  margin: 1.2rem;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .aboutUsCard {
    flex-direction: column-reverse;
    justify-content: center;
  }
}

/* body {
  overflow: scroll;
}

.container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 960px;
  margin-bottom: 100px;
  justify-content: center;
}

.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: brown solid 2px;
}

.aboutUsCard {
  padding-bottom: 20px;
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: row;
  justify-content: center;
}
.imageContainer {
  width: 40%;
  margin: 1rem;
}
.dataContainer {
  flex: 3;
  object-fit: contain;
  text-align: left;
  padding: 20px;
  flex-direction: row;
  align-self: flex-start;
}

@media only screen and (max-width: 767px){
  .aboutUsCard{
    flex-direction:column-reverse;
  }
} */
