.main_wrapper body {
  overflow: hidden;
}

.main_wrapper body,
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main_wrapper ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main_wrapper li {
  margin-top: 10px;
}

.section1 {
  width: 100vw;
  max-width: 100%;
  height: 750px;
  position: relative;
  /* top: 0; */
  z-index: -9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sectionFloat {
  width: 100vw;
  max-width: 100%;
  height: 750px;
  position: relative;
  /* top: 0; */
  z-index: -8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section2 {
  width: 100vw;
  max-width: 100%;
  height: 300px;
  position: relative;
  /* top: calc(750px * 1); */
  /* z-index: 99; */
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-stretch: extra-expanded;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.section3 {
  width: 100vw;
  max-width: 100%;
  height: 750px;
  position: relative;
  /* top: calc(750px * 2) */
  z-index: -9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section4 {
  width: 100vw;
  max-width: 100%;
  height: 650px;
  position: relative;
  /* top: calc(750px * 3) */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section5 {
  width: 100vw;
  max-width: 100%;
  height: 750px;
  position: relative;
  /* top: calc(750px * 4) */
  z-index: -9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* ++++++++++++ sectionen1 children +++++++++++++++++ */

.dog_background {
  position: absolute;
  top: -0.7rem;
}

.logoTopPageActive{
  position: absolute;
  top: 3rem;
  left: 0;
  margin: 0.5rem;
}

/* .logoTopPage{
  position: fixed;
  top: 5rem;
  left: 0;
  margin: 0.5rem;
  visibility: hidden;
} */

.logoTopPageActive img{
  margin: 0.5rem;
  height: 50px;
}


.home_welcome_header {
  display: flex;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100vw;
}

.home_welcome_header > h1 {
  color: white;
  border-bottom: none;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  justify-content: center;
}

.home_welcome_header > h2 {
  font-size: 4rem;
  font-family: "Dancing Script";
  color: white;
}

/* ++++++++++++ sectionen2 children +++++++++++++++++ */
.first_content {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 960px;
}

.first_content h2 {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

/* ++++++++++++ sectionen3 children +++++++++++++++++ */

.second_dog_background {
  position: absolute;
  top: calc(550px * 1.5);
}

/* ++++++++++++ sectionen4 children +++++++++++++++++ */

.section4_Container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.zertifikat_logo img {
  padding: 1rem;
}

.externe_links a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

@media only screen and (max-device-width: 768px) {
  .section1 {
    height: 300px;
  }
  .section2 {
    height: 600px;
    font-size: large;
  }

  .first_content h2 {
    margin-top: 0;
  }
  .section3 {
    height: 400px;
  }
  .section4 {
    height: 400px;
  }
  .section5 {
    height: 400px;
  }

  .zertifikat_logo img {
    width: 100px;
  }

  .home_welcome_header > h1 {
    font-size: 1.5rem;
  }

  .home_welcome_header > h2 {
    font-size: 2rem;
  }


  .logoTopPageActive img{
    width: 30px;
    display: flex;
    flex-direction: column;
    margin: 0;
   margin-bottom: -0.5rem;
  }

  .logoTopPageActive{
    position: fixed;
    top: 4.5rem;
    left: 1rem;
    margin: 0.1rem;
  }

  
}
