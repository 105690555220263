.navigation {
  /* background: red;  */
  background:  rgb(167, 69, 39);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.6);
}

/* .navigation__brown{
  background:  rgb(167, 69, 39);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 3000;
  width: 100%;
  
} */
.navigation_container {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navigation_logo {
  color: white;
  justify-content: flex-start;
  text-decoration: none;
  margin-left: 2.7rem;
  width: max-content;
  cursor: pointer;
  /* font-size: 2rem; */
  /* display: flex; */
  align-items: center;
  /* font-family: 'Josefin Sans', sans-serif; */
}

.navigation_logo h2 {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: medium;
  text-align: right;
  font-style: italic;
}

.navigation_logo h3 {
  color: white;
  text-align: right;
  /* font-style: italic; */
  border-bottom: none;
}

.navigation_menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 2rem;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: flex-end;
  margin-right: 3rem;
}

.menu-button {
  display: flex;
  justify-content: flex-end;
}

/* .nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Josefin Sans', sans-serif;
  border-bottom: 1px solid rgb(167, 69, 39);
} */

/* .nav-links:hover {
  border-bottom: 1px solid white;
  transition: all 0.2s ease-out;
}

.activ-links:focus{
  border-bottom: 1px solid white;
} */

.navigation a {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  height: 100%;
  font-family: "Josefin Sans", sans-serif;
  border-bottom: 1px solid rgb(167, 69, 39);
  font-size: 1.2rem;
}

.navigation > ul > li > a:hover {
  border-bottom: 1px solid white;
  transition: all 0.2s ease-out;
}

.navigation > ul > li > a:focus {
  border-bottom: 1px solid white;
}

.fa-bars {
  color: #fff;
}

/* .nav-links-mobile {
  display: none;
} */

.menu_icon {
  display: none;
  justify-content: flex-end;
  color: white;
}

@media screen and (max-width: 460px) {
}

@media screen and (max-width: 760px) {
  .navigationItems {
    position: relative;
  }

  .navigation {
    background: rgb(167, 69, 39);
  }

  .navigation_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -150%;
    opacity: 1;
    transition: all 0.5s ease;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.6);
  }

  .navigation_menu.active {
    background: rgb(167, 69, 39);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
  }

  .navigation > ul > li > a:hover {
    border-bottom: none;
    transition: none;
  }

  .navigation_logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    color: white;
    align-items: center;
    margin-left: 0px;
  }

  .nav-header {
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: medium;
  }

  .menu_icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
