body {
  overflow: scroll;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding: 15px;
  max-width: 960px;
}

.containerdog1 {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
.dogbox1 {
  flex: 1;
  padding: 20px;
  justify-content: space-between;
}
.dogbox2 {
  flex: 1;
  padding: 20px;
  justify-content: space-between;
}

.headerButtonBack{
  display: flex;
  align-items: center;
}

img {
  padding: 0px 0;
}
.mainHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: brown solid 2px;
}
.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: 0rem;
  /*  border-bottom: brown solid 2px; */
}
.imgRight {
  padding-bottom: 20px;
  max-width: 400px;
  max-height: 320px;
}
.header {
  font-size: 1.5rem;
  color: brown;
  padding-bottom: 8px;
}
.dob {
  padding-bottom: 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
  overflow-x: auto;
}
th,
td {
  padding-right: 10px;
}
.expositions {
  display:flex;
  flex-direction: column;
  padding-top: 20px;
}
.expositions button{
  margin: 0px;
  margin-bottom: 20px;
  max-width: 375px;
} 
.coefficient {
  text-align: center;
}
@media only screen and (max-width: 700px) {
  th,
  td {
    padding-right: 6px;
  }

  .imgRight {

    max-width: 320px;
    max-height: 320px;
  }
}

@media only screen and (max-width: 360px){
  .imgRight {
   
    max-width: 250px;
    max-height: 250px;
  }
}

@media only screen and (max-width: 280px){
  .imgRight {
   
    max-width: 200px;
    max-height: 200px;
  }
}

