body {
  overflow: scroll;
}
.mainText {
  padding: 20px;
}
.container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding: 15px;
  max-width: 960px;
  /* margin-bottom: 100px;
    object-fit: contain; */
}
.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: brown solid 2px;
}
.detailCard {
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex: 7;
  align-items: top;
  padding: 20px;
}
.header {
  font-size: 1.5rem;
  color: brown;
  padding-bottom: 8px;
}
.born {
  padding-bottom: 20px;
}
.dataContainer {
  flex: 3;
  object-fit: contain;
  text-align: left;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
  overflow-x: auto;
}
th,
td {
  padding-right: 20px;
}
.expositions {
  padding-top: 10px;
}
.imageContainer {
  flex: 3;
  text-align: right;
  background-size: cover;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  object-fit: contain;
  padding: 20px;
}
img {
  max-width: 100%;
  object-fit: contain;
}
.imgRight {
  float: right;
}
.imgLeft {
  float: left;
}

.buttonRight {
  float: right;
  margin-top: 20px;
}
button {
  margin: 20px;
}
.card {
  display: flex;
  flex-wrap: wrap;
  /* background: silver; */
}

.card > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 280px;
}

.media {
  /* background: black; */
  min-height: 280px;
  padding: 20px;
}

.ivyButtonPage {
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
}

.ivyButtonPage button {
  margin: 0;
}

.showPedigreeImage {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  margin-top: 3rem;
}

.showGalleryIvyPage {
  max-width: 960px;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.expositionsIvyPage {
  margin-right: 1.2rem;
  margin-left: 1.2rem;
}

.expositionsIvyPage div {
  margin-bottom: 2rem;
}

.expositionsIvyPageImage {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.expositionsIvyPageImage img {
  width: 250px;
}

@media only screen and (max-width: 767px) {
  th,
  td {
    padding-right: 6px;
  }
  button {
    margin: 6px;
  }

  .ivyButtonPage button {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    margin: 0.1rem;
  }

  .expositionsIvyPageImage {
    flex-direction: column;
  }
  .txtExpositions{
    display: row;
  }

  .expositionsIvyPageImage img {
    width: auto;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .expositionsIvyPageImage img {
    width: 200px;
  }
}
