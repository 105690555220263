
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
/*   font-family: 'PT Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding: 15px;
  width: 960px;
/*   margin-top: 80px; */
  margin-bottom: 100px;
  object-fit: contain;
}

.doglist{
  width:100%;
}

/* +++++++++++ FrameWork Carousel ++++++++++ */
.carousel .slide{
  background: transparent !important;
  height: 100% !important;
  
}

.carousel-root{
  margin: 1rem !important;
  width: 25rem !important;
}

.carousel .slide img{
  width: 25rem !important;
  
}

.carousel .control-dots .dot{
  background: rgb(167, 69, 39) !important;
  opacity: 0.1;
}

@media screen and (max-width: 767px){

  .carousel-root{
    width: 19rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }


  .carousel .slide img{
    width: 19rem !important;
    
  }
}

@media screen and (max-width: 320px) {
  .carousel-root{
    width: 15rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .carousel .slide img{
    width: 15rem !important;
    
  }
}


/* +++++++++++ Framework React Bootstrap Dropdown-Menu ++++++++++ */
.dropdown button{
  margin: 0;
  display: flex;
  justify-content: baseline;
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
  height: 100%;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
}

.dropdown-menu a{
  background-color: rgb(167, 69, 39) !important ;
  /* background: rgb(167, 69, 39) !important; */
  box-shadow: 0 10px 25px rgb(167, 69, 39) !important ;  
  border-bottom: rgb(167, 69, 39) !important;
  padding: 0.8rem 1rem !important;
  margin: 0 0 0 0;
}

/* .fa, .far, .fas{
  font-size: 1.2rem !important;
} */

.fa, .fab, .fad, .fal, .far, .fas{
  padding-bottom: 8px;
}

