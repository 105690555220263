.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding: 15px;
  max-width: 960px;
}
.mainHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: brown solid 2px;
}
.mainHeader {
  font-size: 2rem;
  color: brown;
  display: flex;
  justify-items: end;
  padding: 20px 20px;
  border-bottom: 0rem;
}

.headerButtonBack{
  display: flex;
  align-items: center;
}

.headerButtonBack{
  display: flex;
  align-items: center;
}